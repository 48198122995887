import React from "react";

import designaidlogo from "../images/logo/design_aid_logo.svg";
import usericon from "../images/icons/regular/shopping-bag-open.svg";

import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function Menu() {
  const logout = () => {
    // destroy the cookie
    cookies.remove("TOKEN", { path: "/" });
    cookies.remove("COMPANY", { path: "/" });
    cookies.remove("EMAIL", { path: "/" });
    cookies.remove("USERNAME", { path: "/" });
    cookies.remove("ID", { path: "/" });
    cookies.remove("ADMIN", { path: "/" });
    // redirect user to the landing page
    window.location.href = "/";
  };

  return (
    <div className="menu">
      <section className="navigation">
        <div className="menu-logo">
          <img src={designaidlogo} alt="Design Aid" />
        </div>
        {cookies.get("COMPANY") && (
          <ul>
            <a href={`/invite/${cookies.get("COMPANY")}`}>
              <img src={usericon} className="nav-item-logo" alt="plan" />
              Invite user
            </a>
            <a href="/">
              <img src={usericon} className="nav-item-logo" alt="plan" />
              Frontpage
            </a>

            <a href={`/company/${cookies.get("COMPANY")}`}>
              <img src={usericon} className="nav-item-logo" alt="plan" />
              Company
            </a>

            <a href={`/colors/company/${cookies.get("COMPANY")}`}>
              <img src={usericon} className="nav-item-logo" alt="plan" />
              Company colors
            </a>
            {cookies.get("ADMIN") && (
              <>
                <a href="/companies">
                  <img src={usericon} className="nav-item-logo" alt="plan" />
                  List companies
                </a>

                <a href="/users">
                  <img src={usericon} className="nav-item-logo" alt="plan" />
                  List users
                </a>
              </>
            )}
          </ul>
        )}
      </section>
      <div className="menu-footer">
        <div className="menu-footer-status-label">Logged in as</div>
        <div className="menu-footer-status">{cookies.get("USERNAME")}</div>
        <button type="submit" variant="danger" onClick={() => logout()}>
          Logout
        </button>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import axios from "axios";
import LoadingButton from "../components/LoadingButton";

import Menu from "../components/Menu";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const token = cookies.get("TOKEN");

export default function CreateCompanyPage() {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function validateCodeForm() {
    // TODO: way too simple validation
    return name.length > 0;
  }

  const handleSubmit = (e) => {
    // prevent the form from refreshing the whole page
    e.preventDefault();

    setIsSendingCode(true);

    // set configurations
    const configuration = {
      method: "post",
      url: "http://localhost:8081/companies/new",
      headers: {
        Authorization: token,
      },
      data: {
        name,
        username: cookies.get("USERNAME"),
      },
    };

    console.log(configuration);

    // make the API call
    axios(configuration)
      .then((result) => {
        console.log(result);
        cookies.set("COMPANY", result.data.company, { path: "/" });
        window.location.href = `/company/${result.data.company}`;
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(error.message);
        setIsSendingCode(false);
      });
  };

  return (
    <div className="app">
      <Menu />
      <main>
        <div className="msg-screen">
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="createcompany-form"
          >
            {errorMessage && (
              <div className="login-error-message">{message}</div>
            )}

            <h3>
              Before we start, you need to enter some details about your
              company:
            </h3>
            <label htmlFor="name" className="large-label">
              Company
            </label>
            <input
              id="name"
              name="name"
              type="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Company Name"
            />
            <LoadingButton
              disabled={!validateCodeForm()}
              isLoading={isSendingCode}
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              Create
            </LoadingButton>
            {message && <div className="login-status-message">{message}</div>}
          </form>
        </div>
      </main>
    </div>
  );
}

import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const token = cookies.get("TOKEN");
const id = cookies.get("ID");

export const getCompaniesFn = async () => {
  const configuration = {
    method: "get",
    url: `http://localhost:8081/companies/all/`,
    headers: {
      Authorization: token,
    },
  };

  const response = await axios(configuration);
  return response.data;
};

export const getCompanyFn = async (company_id) => {
  const configuration = {
    method: "get",
    url: `http://localhost:8081/companies/get/${company_id}`,
    headers: {
      Authorization: token,
    },
  };

  const response = await axios(configuration);
  return response.data;
};

export const updateCompanyFn = async (company) => {
  let socials = company.socials.split(",");
  if (company.newsocial.length > 0) {
    if (!socials) {
      socials = [];
    }
    socials.push(company.newsocial);
  }
  const configuration = {
    method: "post",
    url: `http://localhost:8081/companies/update/${company.id}`,
    headers: {
      Authorization: token,
    },
    data: {
      _id: company.id,
      url: company.url,
      billingEmail: company.billingEmail,
      socials: socials,
    },
  };

  const response = await axios(configuration);
  //console.log(response.data);
  return response.data;
};

export const updateColorsFn = async (colorscheme) => {
  const configuration = {
    method: "post",
    url: `http://localhost:8081/colorschemes/new/${colorscheme.id}`,
    headers: {
      Authorization: token,
    },
    data: {
      primarycolor: colorscheme.primarycolor,
      secondarycolor: colorscheme.secondarycolor,
      tertiarycolor: colorscheme.tertiarycolor,
      backgroundcolor: colorscheme.backgroundcolor,
      textcolor: colorscheme.textcolor,
      companyid: colorscheme.id,
    },
  };

  const response = await axios(configuration);
  //console.log(response.data);
  return response.data;
};

export const getUserFn = async (userid) => {
  const configuration = {
    method: "get",
    url: `http://localhost:8081/users/get/${userid}`,
    headers: {
      Authorization: token,
    },
  };

  const response = await axios(configuration);
  return response.data;
};

export const getUsersFn = async () => {
  const configuration = {
    method: "get",
    url: `http://localhost:8081/users/all/`,
    headers: {
      Authorization: token,
    },
  };

  const response = await axios(configuration);
  return response.data;
};

export const getColorschemeFn = async (colorscheme_id) => {
  const configuration = {
    method: "get",
    url: `http://localhost:8081/colorschemes/get/${colorscheme_id}`,
    headers: {
      Authorization: token,
    },
  };

  const response = await axios(configuration);
  return response.data;
};

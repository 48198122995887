import React from "react";
import denmark from "../images/misc/denmark.png";
import footerlogo from "../images/logo/designaid_footer_logo.svg";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footer-wrapper">
          <div className="footer">
            <div className="footer-logo footer-col">
              <img src={footerlogo} alt="Design Aid Logo" />
              <p>“Design As A Service” to grow your business</p>
            </div>
            <div className="footer-location footer-col">
              <div className="left">
                <img src={denmark} alt="map of Denmark" />
              </div>
              <div className="right">
                DesignAid is located in Denmark. All the work is done by a
                Danish designer with 20+ years experience.
              </div>
            </div>
            <div className="footer-spacer footer-col"></div>

            <div className="footer-links1 footer-col">
              <a href="#how">The Process</a>
              <br />
              <a href="#pricing">Membership Plans</a>
              <br />
              <a href="/terms">Terms & Conditions</a>
            </div>
            <div className="footer-links2 footer-col">
              <a
                href="http://www.twitter.com/designaid_io"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

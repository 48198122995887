import { Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./components/ProtectedRoutes";

import FrontPage from "./components/FrontPage";
import AcrylicPack01 from "./pages/ResourcePages/AcrylicPack01";

import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ResendConfirmPage from "./pages/ResendConfirmPage";
import ConfirmEmailPage from "./pages/ConfirmEmailPage";
import CompanyPage from "./pages/CompanyPage";
import CompanyList from "./pages/CompanyList";
import CreateCompanyPage from "./pages/CreateCompanyPage";
import ColorPage from "./pages/ColorPage";
import ColorschemePage from "./pages/ColorschemePage";
import UserList from "./pages/UserList";
import TermsPage from "./pages/TermsPage";
import NoMatch from "./pages/NoMatch";

import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";

import "./App.scss";
import InvitePage from "./pages/InvitePage";
import User from "./pages/User";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<FrontPage />} />
      <Route exact path="/ressources/acrylic01" element={<AcrylicPack01 />} />
      <Route exact path="/terms" element={<TermsPage />} />
      <Route exact path="/login" element={<LoginPage />} />
      <Route exact path="/register/:id" element={<RegisterPage />} />
      <Route exact path="/register" element={<RegisterPage />} />
      <Route exact path="/resend-confirm" element={<ResendConfirmPage />} />
      <Route exact path="/confirm/:id" element={<ConfirmEmailPage />} />
      <Route
        exact
        path="/company/:id"
        element={
          <ProtectedRoutes>
            <CompanyPage />
          </ProtectedRoutes>
        }
      />
      <Route
        exact
        path="/companies"
        element={
          <ProtectedRoutes>
            <CompanyList />
          </ProtectedRoutes>
        }
      />
      <Route
        exact
        path="/create-company"
        element={
          <ProtectedRoutes>
            <CreateCompanyPage />
          </ProtectedRoutes>
        }
      />
      <Route
        exact
        path="/colors/company/:id"
        element={
          <ProtectedRoutes>
            <ColorPage />
          </ProtectedRoutes>
        }
      />
      <Route
        exact
        path="/colors/show/:id"
        element={
          <ProtectedRoutes>
            <ColorschemePage />
          </ProtectedRoutes>
        }
      />
      <Route
        exact
        path="/users"
        element={
          <ProtectedRoutes>
            <UserList />
          </ProtectedRoutes>
        }
      />
      <Route
        exact
        path="/user/:id"
        element={
          <ProtectedRoutes>
            <User />
          </ProtectedRoutes>
        }
      />
      <Route
        exact
        path="/invite/:id"
        element={
          <ProtectedRoutes>
            <InvitePage />
          </ProtectedRoutes>
        }
      />

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default App;

import React from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { getCompanyFn, updateColorsFn } from "../api/functions";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

import Menu from "../components/Menu";

const ColorPage = (props) => {
  const queryClient = new useQueryClient();
  const { id } = useParams();

  const isHex = /^#[0-9A-F]{6}$/i;

  const { isSuccess, isLoading, isError, error, data } = useQuery({
    queryKey: ["company"],
    queryFn: () => getCompanyFn(id),
    staleTime: Infinity,
  });

  const { register, handleSubmit, reset, formState } = useForm();

  const { mutate } = useMutation({
    mutationFn: (values) => updateColorsFn(values),
    // ✅ return Promise from invalidation
    // so that it will be awaited
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["company"] }),
  });

  if (isLoading) return <>"Loading..."</>;

  if (isError) return "An error has occured: " + error.message;

  if (isSuccess) {
    return (
      <>
        <div className="app">
          <Menu />
          <main>
            <div className="backend-screen">
              <div className="container-narrow">
                <h1>Company Colorschemes</h1>
                <br />
                {data.colorschemes.map((colorscheme, i) => (
                  <div key={i} className="company-scheme">
                    <div className="company-scheme-colors">
                      {colorscheme.primarycolor && (
                        <div className="company-scheme-color">
                          <div className="company-scheme-name">Primary</div>
                          <div
                            className="company-scheme-color-hex"
                            style={{
                              backgroundColor: `${colorscheme.primarycolor}`,
                            }}
                          ></div>
                        </div>
                      )}
                      {colorscheme.secondarycolor && (
                        <div className="company-scheme-color">
                          <div className="company-scheme-name">Secondary</div>
                          <div
                            className="company-scheme-color-hex"
                            style={{
                              backgroundColor: `${colorscheme.secondarycolor}`,
                            }}
                          ></div>
                        </div>
                      )}
                      {colorscheme.tertiarycolor && (
                        <div className="company-scheme-color">
                          <div className="company-scheme-name">Tertiary</div>
                          <div
                            className="company-scheme-color-hex"
                            style={{
                              backgroundColor: `${colorscheme.tertiarycolor}`,
                            }}
                          ></div>
                        </div>
                      )}
                      {colorscheme.backgroundcolor && (
                        <div className="company-scheme-color">
                          <div className="company-scheme-name">Background</div>
                          <div
                            className="company-scheme-color-hex"
                            style={{
                              backgroundColor: `${colorscheme.backgroundcolor}`,
                            }}
                          ></div>
                        </div>
                      )}
                      {colorscheme.textcolor && (
                        <div className="company-scheme-color">
                          <div className="company-scheme-name">Text</div>
                          <div
                            className="company-scheme-color-hex"
                            style={{
                              backgroundColor: `${colorscheme.textcolor}`,
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {!formState.isValid && formState.isSubmitted ? (
                  <div className="form-alert-danger">
                    {Object.values(formState.errors).map((e, idx) => {
                      return <p key={idx}>{e.message}</p>;
                    })}
                  </div>
                ) : (
                  <div></div>
                )}
                <form
                  className="add-colorscheme-form"
                  onSubmit={handleSubmit((values) =>
                    // ✅ reset client state back to undefined
                    mutate(values, { onSuccess: () => reset() })
                  )}
                >
                  <input
                    {...register("id")}
                    type="hidden"
                    defaultValue={data._id}
                  />

                  <div>
                    <label htmlFor="primarycolor">Primary color:</label>
                    <input
                      {...register("primarycolor", {
                        required: {
                          value: true,
                          message: "Primary color is required",
                        },
                        pattern: {
                          value: isHex,
                          message: "Need to be in HEX format like #f84fa4",
                        },
                      })}
                    />
                  </div>
                  <div>
                    <label htmlFor="secondarycolor">Secondary color:</label>
                    <input
                      {...register("secondarycolor", {
                        required: {
                          value: false,
                        },
                        pattern: {
                          value: isHex,
                          message: "Need to be in HEX format like #f84fa4",
                        },
                      })}
                    />
                  </div>
                  <div>
                    <label htmlFor="tertiarycolor">Tertiary color:</label>
                    <input
                      {...register("tertiarycolor", {
                        required: {
                          value: false,
                        },
                        pattern: {
                          value: isHex,
                          message: "Need to be in HEX format like #f84fa4",
                        },
                      })}
                    />
                  </div>
                  <div>
                    <label htmlFor="backgroundcolor">Background color:</label>
                    <input
                      {...register("backgroundcolor", {
                        required: {
                          value: false,
                        },
                        pattern: {
                          value: isHex,
                          message: "Need to be in HEX format like #f84fa4",
                        },
                      })}
                    />
                  </div>
                  <div>
                    <label htmlFor="textcolor">Text color:</label>
                    <input
                      {...register("textcolor", {
                        required: {
                          value: true,
                          message: "Text color is required",
                        },
                        pattern: {
                          value: isHex,
                          message: "Need to be in HEX format like #f84fa4",
                        },
                      })}
                    />
                  </div>

                  <input type="submit" value="Add colorscheme" />
                </form>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
};

ColorPage.propTypes = {
  id: PropTypes.string,
};
export default ColorPage;

import React from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { getCompanyFn, updateCompanyFn } from "../api/functions";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

import Menu from "../components/Menu";

const CompanyPage = (props) => {
  const queryClient = new useQueryClient();
  const { id } = useParams();

  const { isSuccess, isLoading, isError, error, data } = useQuery({
    queryKey: ["company"],
    queryFn: () => getCompanyFn(id),
    staleTime: Infinity,
  });

  const { register, handleSubmit, reset } = useForm();

  const { mutate } = useMutation({
    mutationFn: (values) => updateCompanyFn(values),
    // ✅ return Promise from invalidation
    // so that it will be awaited
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["company"] }),
  });

  if (isLoading) return <>"Loading..."</>;

  if (isError) return "An error has occured: " + error.message;

  if (isSuccess) {
    return (
      <>
        <div className="app">
          <Menu />
          <main>
            <div className="backend-screen">
              <div className="container-narrow">
                <h1>{data.name}</h1>
                <form
                  onSubmit={handleSubmit((values) =>
                    // ✅ reset client state back to undefined
                    mutate(values, { onSuccess: () => reset() })
                  )}
                >
                  <input
                    {...register("id")}
                    type="hidden"
                    defaultValue={data._id}
                  />
                  <input
                    {...register("socials")}
                    type="hidden"
                    defaultValue={data.socials}
                  />
                  <div>
                    <label htmlFor="url">Website</label>
                    <input {...register("url")} defaultValue={data.url} />
                  </div>
                  <div>
                    <label htmlFor="url">Billing email</label>
                    <input
                      {...register("billingEmail")}
                      defaultValue={data.billingEmail}
                    />
                  </div>
                  Social media urls:
                  {data.socials.map((social, i) => (
                    <div key={i} className="company-social">
                      <Link to={social} target="_blank" rel="noreferrer">
                        {social}
                      </Link>
                    </div>
                  ))}
                  <div>
                    <label htmlFor="newsocial">Add:</label>
                    <input {...register("newsocial")} />
                  </div>
                  <input type="submit" value="Update" />
                </form>
                <br />
                <h3>Color schemes</h3>
                <div className="company-mini-schemes">
                  {data.colorschemes.map((colorscheme, i) => (
                    <div key={i} className="company-mini-scheme">
                      <div className="company-mini-scheme-colors">
                        {colorscheme.primarycolor && (
                          <div className="company-mini-scheme-color">
                            <div className="company-mini-scheme-name">
                              Primary color
                            </div>
                            <div
                              className="company-mini-scheme-color-hex"
                              style={{
                                backgroundColor: `${colorscheme.primarycolor}`,
                              }}
                            ></div>
                          </div>
                        )}
                        {colorscheme.secondarycolor && (
                          <div className="company-mini-scheme-color">
                            <div className="company-mini-scheme-name">
                              Secondary color
                            </div>
                            <div
                              className="company-mini-scheme-color-hex"
                              style={{
                                backgroundColor: `${colorscheme.secondarycolor}`,
                              }}
                            ></div>
                          </div>
                        )}
                        {colorscheme.tertiarycolor && (
                          <div className="company-mini-scheme-color">
                            <div className="company-mini-scheme-name">
                              Tertiary color
                            </div>
                            <div
                              className="company-mini-scheme-color-hex"
                              style={{
                                backgroundColor: `${colorscheme.tertiarycolor}`,
                              }}
                            ></div>
                          </div>
                        )}
                        {colorscheme.backgroundcolor && (
                          <div className="company-mini-scheme-color">
                            <div className="company-mini-scheme-name">
                              Background color
                            </div>
                            <div
                              className="company-mini-scheme-color-hex"
                              style={{
                                backgroundColor: `${colorscheme.backgroundcolor}`,
                              }}
                            ></div>
                          </div>
                        )}
                        {colorscheme.textcolor && (
                          <div className="company-mini-scheme-color">
                            <div className="company-mini-scheme-name">
                              Text color
                            </div>
                            <div
                              className="company-mini-scheme-color-hex"
                              style={{
                                backgroundColor: `${colorscheme.textcolor}`,
                              }}
                            ></div>
                          </div>
                        )}
                        <div className="company-mini-scheme-color-link">
                          <a href={`/colors/show/${colorscheme._id}`}>
                            details
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                  <Link to={`/colors/company/${id}#add`}>
                    ... add colorscheme
                  </Link>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
};

CompanyPage.propTypes = {
  id: PropTypes.string,
};
export default CompanyPage;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Spinner from "../components/Spinner";

export default function ConfirmEmailPage() {
  // A bit of state to give the user feedback while their email
  // address is being confirmed on the User model on the server.
  const [confirming, setConfirming] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    fetch(`http://localhost:8081/users/email/confirm/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setConfirming(false);
        //notify.show(data.msg);
        console.log(data.msg);
      })
      .catch((err) => console.log(err));
  }, [id]);

  // While the email address is being confirmed on the server a spinner is
  // shown that gives visual feedback. Once the email has been confirmed the
  // spinner is stopped and turned into a button that takes the user back to the
  // <Landing > component so they can confirm another email address.
  return (
    <div className="confirm">
      <div className="confirm-message">
        {confirming ? (
          <Spinner size="8x" spinning={"spinning"} />
        ) : (
          <div>
            <p>Email confirmed!</p>
            <Link to="/login">
              <button>Go to Login</button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

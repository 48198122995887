import React from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import checkMark from "../../images/icons/regular/check.svg";

import acrylic_cover from "../../images/ressources/acrylic/designaid_acrylic_wallpaper_thumb.jpg";
import acrylic_preview01 from "../../images/ressources/acrylic/preview/designaid_acrylic_wallpaper01.jpeg";
import acrylic_preview02 from "../../images/ressources/acrylic/preview/designaid_acrylic_wallpaper02.jpeg";
import acrylic_preview03 from "../../images/ressources/acrylic/preview/designaid_acrylic_wallpaper03.jpeg";
import acrylic_preview04 from "../../images/ressources/acrylic/preview/designaid_acrylic_wallpaper04.jpeg";
import acrylic_preview05 from "../../images/ressources/acrylic/preview/designaid_acrylic_wallpaper05.jpeg";
import acrylic_preview06 from "../../images/ressources/acrylic/preview/designaid_acrylic_wallpaper06.jpeg";
import Seo from "../../components/Seo";

import seoImg from "../../images/ressources/acrylic/designaid_acrylic_seo_thumb.jpg";

export default function AcrylicPack01() {
  const metaImgObj = {
    src: seoImg,
    width: 1200,
    height: 1200,
  };
  return (
    <>
      <Seo
        title="Design Aid - Design as a service"
        description="Free acrylic texture pack. These acrylic texutres will boost
        social media engagement when combined with your branded logo
        presentation. And they are absolutely free - this is essential to
        your arsenal of assets."
        type="article"
        siteUrl="http://www.designaid.io"
        pathname="/ressources/acrylic01"
        name="designaid_io"
        image={metaImgObj}
      />
      <Header />
      <div className="front-main wide-container">
        <div className="container ressource-page">
          <div className="ressource-cover">
            <img src={acrylic_cover} alt="acrylic pack cover" />
          </div>
          <div className="ressource-details">
            <h1>Acrylic Texture Pack 01</h1>
            <div className="subtitle">
              Textures for Wallpaper, Social Media, Adobe Suite
            </div>
            <p>
              Acrylic visual perfection, these acrylic texutres will boost
              social media engagement when combined with your branded logo
              presentation. And they are absolutely free - this is essential to
              your arsenal of assets.
            </p>
            <ul className="ressource-list">
              <li className="ressource-list-item">
                <img src={checkMark} alt="checkmark" />
                4K
              </li>
              <li className="ressource-list-item">
                <img src={checkMark} alt="checkmark" />
                Diverse visualizations
              </li>
              <li className="ressource-list-item">
                <img src={checkMark} alt="checkmark" />6 Abstract Exports
              </li>
            </ul>
            <div className="ressource-link">
              <a
                href="https://designaid.gumroad.com/l/bdyby"
                target="_blank"
                rel="noreferrer"
              >
                <button>Get for free</button>
              </a>
            </div>
            <div className="ressource-previews">
              <div className="ressource-preview-image">
                <img src={acrylic_preview01} alt="preview 01" />
              </div>
              <div className="ressource-preview-image">
                <img src={acrylic_preview02} alt="preview 02" />
              </div>
              <div className="ressource-preview-image">
                <img src={acrylic_preview03} alt="preview 03" />
              </div>
              <div className="ressource-preview-image">
                <img src={acrylic_preview04} alt="preview 04" />
              </div>
              <div className="ressource-preview-image">
                <img src={acrylic_preview05} alt="preview 05" />
              </div>
              <div className="ressource-preview-image">
                <img src={acrylic_preview06} alt="preview 06" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

import React, { useState } from "react";
import axios from "axios";

import LoadingButton from "../components/LoadingButton";
import { useParams } from "react-router-dom";

import Menu from "../components/Menu";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const user = cookies.get("USERNAME");
const token = cookies.get("TOKEN");

const InvitePage = (props) => {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function validateCodeForm() {
    return email.length > 0 && /\S+@\S+\.\S+/.test(email);
  }

  const { id } = useParams();

  const handleSubmit = (e) => {
    // prevent the form from refreshing the whole page
    e.preventDefault();

    setIsSendingCode(true);

    // set configurations
    const configuration = {
      method: "post",
      url: "http://localhost:8081/users/invite",
      headers: {
        Authorization: token,
      },
      data: {
        email,
        username: user,
      },
    };

    console.log(configuration);

    // make the API call
    axios(configuration)
      .then((result) => {
        //setRegister(true);
        setIsSendingCode(false);
        setMessage("New user invited");
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setIsSendingCode(false);
      });
  };

  return (
    <>
      <div className="app">
        <Menu />
        <main>
          <div className="backend-screen">
            <div className="container-narrow">
              {errorMessage && (
                <div className="login-error-message">{message}</div>
              )}
              <form onSubmit={(e) => handleSubmit(e)} className="invite-form">
                <h3>Invite another user from your company</h3>
                <label htmlFor="email" className="large-label">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                />
                <LoadingButton
                  disabled={!validateCodeForm()}
                  isLoading={isSendingCode}
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  Invite
                </LoadingButton>
                {message && (
                  <div className="login-status-message">{message}</div>
                )}
              </form>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default InvitePage;

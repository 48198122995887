import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

const Seo = ({ title, description, name, type, image, pathname, siteUrl }) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <link rel="canonical" href={`${siteUrl}${pathname}`} />
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${siteUrl}${image.src}`} />
      <meta property="og:image:width" content={image.width} />
      <meta property="og:image:height" content={image.height} />
      <meta property="og:url" content={`${siteUrl}${pathname}`} />
      <meta property="fb:app_id" content="1331245924461278" />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${siteUrl}${image.src}`} />
      {/* End Twitter tags */}
      <script
        defer
        data-domain="designaid.io"
        src="https://plausible.io/js/script.js"
      ></script>
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  pathname: PropTypes.string.isRequired,
};

export default Seo;

import React from "react";
import HeroButton from "./HeroButton";

import markerIcon from "../images/icons/regular/marker-circle.svg";
import uploadIcon from "../images/icons/regular/upload.svg";
import recycleIcon from "../images/icons/regular/arrows-clockwise.svg";
import checkMark from "../images/icons/regular/check.svg";
import arrowright from "../images/icons/regular/arrow-right.svg";
import browser from "../images/icons/regular/browser.svg";
import mobile from "../images/icons/regular/device-mobile.svg";
import slideshow from "../images/icons/regular/presentation.svg";
import ui from "../images/icons/regular/laptop.svg";
import email from "../images/icons/regular/at.svg";
import monitor from "../images/icons/regular/monitor.svg";
import layout from "../images/icons/regular/layout.svg";
import palette from "../images/icons/regular/palette.svg";
import thumbsup from "../images/icons/regular/thumbs-up.svg";
import heart from "../images/icons/regular/heart.svg";
import certificate from "../images/icons/regular/certificate.svg";

import portfolioImage01 from "../images/portfolio/portfolio01.png";
import portfolioImage02 from "../images/portfolio/portfolio02.png";
import portfolioImage03 from "../images/portfolio/portfolio03.png";
import portfolioImage04 from "../images/portfolio/portfolio04.png";
import profilephoto from "../images/misc/design_aid_photo.png";

import seoImg from "../images/misc/twitter_meta_preview.png";

import Header from "./Header";
import Footer from "./Footer";
import Seo from "./Seo";

export default function Frontpage() {
  const metaImgObj = {
    src: seoImg,
    width: 1200,
    height: 630,
  };
  return (
    <>
      <Seo
        title="Design Aid - Design as a service"
        description="site description"
        type="website"
        siteUrl="http://www.designaid.io"
        pathname="/"
        name="designaid_io"
        image={metaImgObj}
      />
      <Header />
      <div className="front-main wide-container">
        <div className="container">
          <div className="front-hero">
            <h1>A different kind of design agency</h1>
            <div className="front-hero-subtitle">
              "Design as a service" to grow your business without hiring
            </div>
            <HeroButton label="See plans" />
          </div>
        </div>
        <div className="container-full-width">
          <div className="front-examples">
            <div className="front-example">
              <img src={portfolioImage04} alt="design aid, work example" />
            </div>
            <div className="front-example">
              <img src={portfolioImage01} alt="design aid, work example" />
            </div>
            <div className="front-example">
              <img src={portfolioImage02} alt="design aid, work example" />
            </div>
            <div className="front-example">
              <img src={portfolioImage03} alt="design aid, work example" />
            </div>
          </div>
        </div>
        <div className="container" id="how">
          <h2 className="front-how-title">A new way to do design</h2>
          <div className="front-how">
            <div className="front-how-box">
              <div className="front-how-box-icon">
                <img src={uploadIcon} alt="upload" />
              </div>
              <div className="front-how-box-text">
                Subscribe and request as many design jobs as you want
              </div>
            </div>
            <div className="front-how-box">
              <div className="front-how-box-icon">
                <img src={markerIcon} alt="upload" />
              </div>
              <div className="front-how-box-text">
                Depending on complexity you get your design within a couple of
                days (or less)
              </div>
            </div>
            <div className="front-how-box">
              <div className="front-how-box-icon">
                <img src={recycleIcon} alt="upload" />
              </div>
              <div className="front-how-box-text">
                We'll revise the design until you're satisfied
              </div>
            </div>
          </div>
        </div>

        <div className="container-full-width">
          <div className="front-services">
            <div className="container">
              <h2>All of your design need met</h2>
              <div className="subtitle">
                examples of what we can do for you:
              </div>
              <div className="front-services-list">
                <div className="front-service">
                  <img src={browser} alt="icon" />
                  <span>Landing Pages</span>
                </div>
                <div className="front-service">
                  <img src={mobile} alt="icon" />
                  <span>Mobile Apps</span>
                </div>
                <div className="front-service">
                  <img src={ui} alt="icon" />
                  <span>UI</span>
                </div>
              </div>
              <div className="front-services-list">
                <div className="front-service">
                  <img src={monitor} alt="icon" />
                  <span>Web Apps</span>
                </div>
                <div className="front-service">
                  <img src={palette} alt="icon" />
                  <span>Design System</span>
                </div>
                <div className="front-service">
                  <img src={slideshow} alt="icon" />
                  <span>Slide Presentations</span>
                </div>
              </div>
              <div className="front-services-list">
                <div className="front-service">
                  <img src={layout} alt="icon" />
                  <span>Wireframes</span>
                </div>
                <div className="front-service">
                  <img src={thumbsup} alt="icon" />
                  <span>Social Media</span>
                </div>
                <div className="front-service">
                  <img src={email} alt="icon" />
                  <span>Email Templates</span>
                </div>
              </div>
              <div className="subtitle">and much more ...</div>
            </div>
          </div>
        </div>
        <div className="container" id="pricing">
          <h2 className="front-plan-title">Membership levels</h2>
          <div className="front-plan">
            <div className="front-plan-box">
              <div className="front-plan-period">
                <h3>Monthly</h3>
              </div>
              <div className="front-plan-list-item">
                <img src={checkMark} alt="checkMark" />
                Unlimited request
              </div>
              <div className="front-plan-list-item">
                <img src={checkMark} alt="checkMark" />
                Unlimited revisions
              </div>
              <div className="front-plan-list-item">
                <img src={checkMark} alt="checkMark" />
                1:1 via Slack
              </div>
              <div className="front-plan-list-item">
                <img src={checkMark} alt="checkMark" />
                100% Async
              </div>
              <div className="front-plan-bottom">
                <div className="front-plan-price">
                  $1200<span className="text-grey">/month</span>
                </div>
                <div className="front-plan-price-small">
                  DKK8.200<span className="text-grey">/month</span>
                </div>
                <div className="front-plan-button">
                  <a
                    href="https://buy.stripe.com/9AQ6qs3E82mYe7m288"
                    className="button-icon-right"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Choose</span>
                    <div className="icon">
                      <img
                        src={arrowright}
                        className="button-logo"
                        alt="plan"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="front-plan-box">
              <div className="front-plan-period">
                <h3>Quarterly</h3>
              </div>
              <div className="front-plan-list-item">
                <img src={checkMark} alt="checkMark" />
                Unlimited request
              </div>
              <div className="front-plan-list-item">
                <img src={checkMark} alt="checkMark" />
                Unlimited revisions
              </div>
              <div className="front-plan-list-item">
                <img src={checkMark} alt="checkMark" />
                1:1 via Slack
              </div>
              <div className="front-plan-list-item">
                <img src={checkMark} alt="checkMark" />
                100% Async
              </div>
              <div className="front-plan-bottom">
                <div className="front-plan-price">
                  $1100<span className="text-grey">/month</span>
                </div>
                <div className="front-plan-price-small">
                  DKK7.500<span className="text-grey">/month</span>
                </div>
                <div className="front-plan-button">
                  <a
                    href="https://buy.stripe.com/eVacOQ0rW8Lme7m3cd"
                    className="button-icon-right"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Choose</span>
                    <div className="icon">
                      <img
                        src={arrowright}
                        className="button-logo"
                        alt="plan"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="front-plan-box">
              <div className="front-plan-period">
                <h3>Yearly</h3>
              </div>
              <div className="front-plan-list-item">
                <img src={checkMark} alt="checkMark" />
                Unlimited request
              </div>
              <div className="front-plan-list-item">
                <img src={checkMark} alt="checkMark" />
                Unlimited revisions
              </div>
              <div className="front-plan-list-item">
                <img src={checkMark} alt="checkMark" />
                1:1 via Slack
              </div>
              <div className="front-plan-list-item">
                <img src={checkMark} alt="checkMark" />
                100% Async
              </div>
              <div className="front-plan-bottom">
                <div className="front-plan-price">
                  $1000<span className="text-grey">/month</span>
                </div>
                <div className="front-plan-price-small">
                  DKK6.800<span className="text-grey">/month</span>
                </div>
                <div className="front-plan-button">
                  <a
                    href="https://buy.stripe.com/28o1681w0d1C5AQeUW"
                    className="button-icon-right"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Choose</span>
                    <div className="icon">
                      <img
                        src={arrowright}
                        className="button-logo"
                        alt="plan"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="front-contact container" id="contact">
          <h2 className="front-how-title">Still not convinced?</h2>
          <div className="front-profile">
            <div>
              <img
                src={profilephoto}
                alt="Design Aid portrait"
                className="front-profile-portrait"
              />
            </div>
            <div className="front-profile-text">
              <p>
                Hey, I'm Mikkel from Denmark. I've been designing UI, ideating,
                prototyping and creating great experiences for over 20 years.
              </p>
              <p>
                If you're still in doubt why this is a good way to work together
                or anyting else, feel fre to send me an email at{" "}
                <a href="mailto:info@designaid.io">info@designaid.io</a> if you
                have any questions
              </p>
              <div className="front-profile-text-cols">
                <div className="front-profile-text-col">
                  <img src={certificate} alt="certificate icon" />
                  <p>Over 20 year experience with design and development</p>
                </div>
                <div className="front-profile-text-col">
                  <img src={heart} alt="heart icon" />
                  <p>A huge passion for design and user experience</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

import React, { useState } from "react";
import axios from "axios";
import LoadingButton from "../components/LoadingButton";
import { useParams } from "react-router-dom";

export default function RegisterPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [register, setRegister] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function validateCodeForm() {
    return (
      username.length > 0 &&
      /\S+@\S+\.\S+/.test(username) &&
      password.length > 0 &&
      name.length > 0 &&
      surname.length > 0
    );
  }

  const { id } = useParams();

  const handleSubmit = (e) => {
    // prevent the form from refreshing the whole page
    e.preventDefault();

    setIsSendingCode(true);

    // set configurations
    let configuration = {
      method: "post",
      url: "http://localhost:8081/users/register",
      data: {
        username,
        password,
        name,
        surname,
      },
    };

    if (id) {
      configuration = {
        method: "post",
        url: `http://localhost:8081/users/register/${id}`,
        data: {
          username,
          password,
          name,
          surname,
        },
      };
    }

    console.log(configuration);

    // make the API call
    axios(configuration)
      .then((result) => {
        setRegister(true);
        setIsSendingCode(false);
        window.location.href = "/resend-confirm";
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.msg) {
          setErrorMessage(error.response.data.msg);
        } else {
          if (error.response.data.match("duplicate key")) {
            setErrorMessage("user already exists");
          } else {
            setErrorMessage(error.response.data);
          }
        }
        setIsSendingCode(false);
      });
  };

  return (
    <div className="login">
      <div className="login-form-col">
        <div className="login-form-content">
          {errorMessage && (
            <div className="login-error-message">{errorMessage}</div>
          )}
          <h2>Register as user</h2>
          <div className="light">Please enter your details</div>
          <form onSubmit={(e) => handleSubmit(e)} className="login-form">
            <label htmlFor="name" className="large-label">
              Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              placeholder="Enter name"
              onChange={(e) => setName(e.target.value)}
            />
            <label htmlFor="surname" className="large-label">
              Lastname
            </label>
            <input
              id="surname"
              name="surname"
              type="text"
              placeholder="Enter lastname"
              onChange={(e) => setSurname(e.target.value)}
            />
            <label htmlFor="email" className="large-label">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter email"
            />
            <label htmlFor="password" className="large-label">
              Password
            </label>
            <input
              id="password"
              name="password"
              value={password}
              type="password"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <LoadingButton
              disabled={!validateCodeForm()}
              isLoading={isSendingCode}
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              Login
            </LoadingButton>
          </form>
        </div>
      </div>
      <div className="login-info"></div>
    </div>
  );
}

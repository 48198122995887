import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import designaidlogo from "../images/logo/design_aid_logo.svg";

import howitworks from "../images/icons/regular/puzzle-piece.svg";
import pricing from "../images/icons/regular/shopping-bag-open.svg";
import contact from "../images/icons/regular/envelope.svg";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const company = cookies.get("COMPANY");

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  const handleToggle = (e) => {
    if (mobileMenuActive) {
      setMobileMenuActive(false);
      const modalElement = document.getElementById("mobile-modal-menu");
      modalElement.classList.remove("is-visible");
    } else {
      setMobileMenuActive(true);
      const modalElement = document.getElementById("mobile-modal-menu");
      modalElement.classList.add("is-visible");
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setScrolled(window.pageYOffset > 1)
      );
    }
  }, []);

  return (
    <div
      className={`front-main wide-container ${
        scrolled ? "is-scrolled is-scrolled-down" : "global-scroll-up"
      }`}
    >
      <header aria-label="Page header" className="page-header">
        <nav role="navigation" aria-label="primary" className="desktop-nav">
          <div className="container nav-container">
            <div className="nav-wrapper">
              <div className="nav-logo">
                <div className="logo">
                  <Link to="/">
                    <img src={designaidlogo} alt="Design Aid Logo" />
                  </Link>
                </div>
              </div>
              <div className="front-menu-wrapper">
                <ul className="front-menu">
                  <a href="#how">
                    <li className="front-menu-item">
                      <img
                        src={howitworks}
                        className="front-menu-item-logo"
                        alt="plan"
                      />
                      How it works
                    </li>
                  </a>
                  <a href="#pricing">
                    <li className="front-menu-item">
                      <img
                        src={pricing}
                        className="front-menu-item-logo"
                        alt="plan"
                      />{" "}
                      Pricing
                    </li>
                  </a>
                  <a href="#contact">
                    <li className="front-menu-item">
                      <img
                        src={contact}
                        className="front-menu-item-logo"
                        alt="plan"
                      />
                      Contact
                    </li>
                  </a>
                  <li className="front-menu-item">
                    {company ? (
                      <Link to={`/company/${company}`} className="button">
                        <span>Client login</span>
                      </Link>
                    ) : (
                      <Link to="/login" className="button">
                        <span>Login</span>
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className="mobile-header">
        <div className="mobile-header-nav">
          <div className="mobile-header-logo">
            <div className="mobile-logo">
              <div className="logo">
                <img src={designaidlogo} alt="Design Aid Logo" />
              </div>
            </div>
          </div>
          <div
            className={`mobile-header-nav-toggle ${
              mobileMenuActive ? "mobile-menu-active" : ""
            } `}
          >
            <button
              className="mobile-toggle-button"
              onClick={(e) => handleToggle(e)}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <div id="mobile-modal-menu" className="mobile-modal-menu">
        <div className="mobile-modal-navigation">
          <a href="/#how" onClick={(e) => handleToggle(e)}>
            <li className="mobile-menu-item">
              <img
                src={howitworks}
                className="mobile-menu-item-logo"
                alt="plan"
              />
              How it works
            </li>
          </a>
          <a href="#pricing" onClick={(e) => handleToggle(e)}>
            <li className="mobile-menu-item">
              <img src={pricing} className="mobile-menu-item-logo" alt="plan" />{" "}
              Pricing
            </li>
          </a>
          <a href="#contact" onClick={(e) => handleToggle(e)}>
            <li className="mobile-menu-item">
              <img src={contact} className="mobile-menu-item-logo" alt="plan" />
              Contact
            </li>
          </a>
        </div>
        <div className="mobile-modal-login">
          <div>Already a member?</div>
          <div>
            <Link to="/login">
              <button>Login</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  locale: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
  locale: `da-dk`,
};

export default Header;

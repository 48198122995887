import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getUserFn } from "../api/functions";
import { useParams } from "react-router-dom";

import Menu from "../components/Menu";

const User = () => {
  const { id } = useParams();

  const { isSuccess, isLoading, isError, error, data } = useQuery({
    queryKey: ["users"],
    queryFn: () => getUserFn(id),
    staleTime: Infinity,
  });

  if (isLoading) return <>"Loading..."</>;

  if (isError) return "An error has occured: " + error.message;

  if (isSuccess) {
    console.log(data);
    return (
      <>
        <div className="app">
          <Menu />
          <main>
            <div className="backend-screen">
              <div className="container-narrow">
                <h1>
                  {data.name} {data.surname}
                </h1>
                <div>
                  <div>Company:</div>
                  <div>{data.company.name}</div>
                  <div>Confirmed:</div>
                  <div>
                    {data.confirmed ? <span>true</span> : <span>false</span>}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
};

export default User;

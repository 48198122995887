import React, { useState } from "react";
import axios from "axios";
import LoadingButton from "../components/LoadingButton";

export default function ResendConfirmPage() {
  const [email, setEmail] = useState("");
  const [resend, setResend] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function validateCodeForm() {
    return email.length > 0 && /\S+@\S+\.\S+/.test(email);
  }

  const handleSubmit = (e) => {
    // prevent the form from refreshing the whole page
    e.preventDefault();

    setIsSendingCode(true);

    // set configurations
    const configuration = {
      method: "post",
      url: "http://localhost:8081/resend-confirmation",
      data: {
        email,
      },
    };

    // make the API call:
    axios(configuration)
      .then((result) => {
        setResend(true);
        setIsSendingCode(false);
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(error.response.data.message);
        setIsSendingCode(false);
      });
  };

  return (
    <div className="login">
      <div className="login-form-col">
        <div className="login-form-content">
          {errorMessage && (
            <div className="login-error-message">{errorMessage}</div>
          )}
          <h2>Unconfirmed</h2>
          <div className="light">
            <p>
              You signed up as a user but need to confirm your email address
              before you can log in. Check your email to find the confirmation
              link or use the form below to send the confirmation link again. If
              you don't receive the email, be sure to check the spam folder!
            </p>
            <p>If you DON'T get the email, you can't try to resend it here:</p>
          </div>
          <form onSubmit={(e) => handleSubmit(e)} className="login-form">
            <label htmlFor="email" className="large-label">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter email"
            />
            <LoadingButton
              disabled={!validateCodeForm()}
              isLoading={isSendingCode}
              type="submit"
              onClick={(e) => handleSubmit(e)}
            >
              Resend
            </LoadingButton>
          </form>
          {resend && (
            <div className="login-status-message">
              New link sent - check your email
            </div>
          )}
        </div>
      </div>
      <div className="login-info"></div>
    </div>
  );
}

import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getColorschemeFn } from "../api/functions";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import Menu from "../components/Menu";

const ColorschemePage = (props) => {
  const { id } = useParams();

  const { isSuccess, isLoading, isError, error, data } = useQuery({
    queryKey: ["company"],
    queryFn: () => getColorschemeFn(id),
    staleTime: Infinity,
  });

  if (isLoading) return <>"Loading..."</>;

  if (isError) return "An error has occured: " + error.message;

  if (isSuccess) {
    return (
      <>
        <div className="app">
          <Menu />
          <main>
            <div className="backend-screen">
              <div className="color-sheme-details">
                <div className="color-scheme-details-color">
                  <div
                    className="color-scheme-details-color-hex"
                    style={{
                      backgroundColor: `${data.primarycolor}`,
                    }}
                  ></div>
                  <div className="color-scheme-details-info">
                    <h4>Primary color</h4>
                    Hex code: {data.primarycolor}
                  </div>
                </div>
                {data.secondarycolor ? (
                  <div className="color-scheme-details-color">
                    <div
                      className="color-scheme-details-color-hex"
                      style={{
                        backgroundColor: `${data.secondarycolor}`,
                      }}
                    ></div>
                    <div className="color-scheme-details-info">
                      <h4>Secondary color</h4>
                      Hex code: {data.secondarycolor}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {data.tertiarycolor ? (
                  <div className="color-scheme-details-color">
                    <div
                      className="color-scheme-details-color-hex"
                      style={{
                        backgroundColor: `${data.tertiarycolor}`,
                      }}
                    ></div>
                    <div className="color-scheme-details-info">
                      <h4>Tertiary color</h4>
                      Hex code: {data.tertiarycolor}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {data.backgroundcolor ? (
                  <div className="color-scheme-details-color">
                    <div
                      className="color-scheme-details-color-hex"
                      style={{
                        backgroundColor: `${data.backgroundcolor}`,
                      }}
                    ></div>
                    <div className="color-scheme-details-info">
                      <h4>Background color</h4>
                      Hex code: {data.backgroundcolor}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {data.textcolor ? (
                  <div className="color-scheme-details-color">
                    <div
                      className="color-scheme-details-color-hex"
                      style={{
                        backgroundColor: `${data.textcolor}`,
                      }}
                    ></div>
                    <div className="color-scheme-details-info">
                      <h4>Text color</h4>
                      Hex code: {data.textcolor}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
};

ColorschemePage.propTypes = {
  id: PropTypes.string,
};
export default ColorschemePage;

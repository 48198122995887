import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import LoadingButton from "../components/LoadingButton";

import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  //const [login, setLogin] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function validateCodeForm() {
    return (
      username.length > 0 &&
      /\S+@\S+\.\S+/.test(username) &&
      password.length > 0
    );
  }

  const handleSubmit = (e) => {
    // prevent the form from refreshing the whole page
    e.preventDefault();

    setIsSendingCode(true);

    // set configurations
    const configuration = {
      method: "post",
      url: "http://localhost:8081/users/login",
      data: {
        username,
        password,
      },
    };

    axios(configuration)
      .then((result) => {
        //console.log(result);
        //TODO: expires sætter lige nu et døgn ligesom på serveren - skal nok forbedres, så de automatisk er i sync eller der skal laves et tjek hver gang en side rammes
        cookies.set("TOKEN", result.data.token, {
          path: "/",
          expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000),
        });
        cookies.set("USERNAME", username, { path: "/" });
        cookies.set("ID", result.data.user.id, { path: "/" });
        if (result.data.user.role === "admin") {
          cookies.set("ADMIN", true, { path: "/" });
        }

        //console.log("----");
        //console.log(result.data.user.company);
        if (result.data.user.company) {
          cookies.set("COMPANY", result.data.user.company, { path: "/" });
          // redirect user to the company page
          window.location.href = "/company/" + result.data.user.company;
        } else {
          window.location.href = "/create-company";
        }
      })
      .catch((error) => {
        setIsSendingCode(false);

        if (error.response.status === 401) {
          window.location.href = "/resend-confirm";
        }
        setErrorMessage(error.message);
      });
  };

  return (
    <div>
      <div className="login">
        <div className="login-info"></div>
        <div className="login-form-col">
          <div className="login-form-content">
            {errorMessage && (
              <div className="login-error-message">{errorMessage}</div>
            )}
            <h2>Welcome back!</h2>
            <div className="light">Please enter your details</div>
            <form onSubmit={(e) => handleSubmit(e)} className="login-form">
              <label htmlFor="username" className="large-label">
                Email
              </label>
              <input
                id="username"
                name="username"
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter email"
              />
              <label htmlFor="password" className="large-label">
                Password
              </label>
              <input
                id="password"
                name="password"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <LoadingButton
                disabled={!validateCodeForm()}
                isLoading={isSendingCode}
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Login
              </LoadingButton>
              <div className="signup-text hide">
                Don't have an account? <Link to="/register">Sign up</Link>
              </div>
              <div className="signup-text hide">
                Forgot password?{" "}
                <Link to="/forgot-password">Reset it here</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
